.customButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px; /* Unutrašnji razmak */
    font-size: 16px; /* Veličina fonta */
    font-weight: 500; /* Debljina fonta */
    border: 1px solid #007bff;
    border-radius: 4px;
    background-color: rgb(0,197,35);
    color: black;
    cursor: pointer;
    transition: all 0.3s ease;
    box-sizing: border-box; /* Računaj padding i border u dimenzije */
    text-align: center;
    white-space: nowrap; /* Sprečava prelamanje teksta */
    overflow: hidden; /* Sakriva višak teksta */
    max-width: 100%; /* Sprečava preveliko dugme */
    font-weight: 600;
    white-space: normal; /* Dozvoljava prelom teksta */
  word-wrap: break-word; /* Prelama reči ako su preduge */
  text-align: center; /* Centriraj tekst u dugmetu */
  padding: 8px; /* Dodaj malo prostora unutar dugmeta */
  }
  
  .customButton:hover {
    
    color:white
  }
  
  .customButton:active {
    transform: scale(0.98);
  }
  