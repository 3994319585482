.cofimage{
  max-width: 100%;
  max-height: 50%;
  overflow: hidden;
}
.bellicon{
  height: 1.7rem;
}

.bellicon {
  font-size: 24px;
  cursor: pointer;
}







@media only screen and (max-width: 600px) {
  .dropdown-item {
      text-align: end!important;
  }
  .cofimage{
    max-width: 85%;
                max-height: 50%;
                overflow: hidden;
  }
}

.dropdown-menu {
  min-width: auto !important;
}

.dropdown {
  margin-right: 1rem;
}




