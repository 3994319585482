.good-status {
  color: green !important;
  font-weight: bold;
}

.not-good-status {
  color: red !important;
  font-weight: bold;
}

.not-filled-yet-status {
  color: yellow !important;
  font-style: italic;
}

.not-filled-status {
  color: gray !important;
}
