






html, body, #root {
  overflow-x: hidden;
  font-family: 'Red Hat Display', sans-serif;
  color: #FFFFFF;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #1d4e5b, #08214c);
  
}

body {
  padding: 5px;
}

:root {
  --button-color: #00c613; /* Boja za unutrašnjost dugmadi i ostale elemente koji imaju boju */
  --text-color: #FFFFFF; /* Boja za slova */
  --background-color: #d5fad7; /* Boja za pozadinu */
}

