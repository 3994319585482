.bm-burger-button {
    position: relative;
    width: 30px;
    height: 25px;
  }
  
  .bm-burger-bars {
    background: #00c613;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: white;
  }
  
  .bm-menu {
    background: linear-gradient(#1d4e5b,#08214c);;
    /* padding: 2.5em 1.5em 0; */
    
    font-size: 1.15em;
  }
  
  .bm-menu-item {
    display: inline-block;
    margin-bottom: 1em;
  }
  
  .bm-menu-item a {
    color: #dcdcdc;
    text-decoration: none;
  }
  
  .bm-menu-item:hover {
    color: #fff;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  .bm-overlay {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  
  .bm-menu-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    transition: all 0.3s;
  }
  .bm-menu {
    background: linear-gradient(to bottom, #204456, #0B2D38);
    font-size: 1.15em;
  }