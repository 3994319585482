input[type="radio"] {
    appearance: none;
    background-color: rgba(29, 78, 91, 0.5); /* Postavite alfa kanal na 0 za potpuno prozirnost */
    margin: 0;
    font: inherit;
    color: green!important;
    width: 1.15em;
    height: 1.15em;
    border: 0.01em solid currentColor;
    border-radius: 50%;
  }
  input[type="radio"]:checked {
   
    border-color: green;
    background-color: #00C613!important;
    background-image: none!important;
  }
  


.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    color: white !important;
  }

  

label{
    color: aliceblue!important;
  }

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input{
    color: white!important;
  }