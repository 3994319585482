.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel{
    color: rgb(0,198,19) !important;
}

.css-1vooibu-MuiSvgIcon-root{
    color: white !important;
}

.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon{
    color:white !important;
}