/* CofButton.module.css */

.button {
    
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    
  }
  
.buttonTransparent {
   
    background-color: transparent;
}

@media (max-width: 767px) {
 
.smallbtn{
  margin-right: 5px;
  width: 100px;
}
}
