.custom-input-container {
  position: relative;
}

.icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.custom-input {
  border-radius: 0;
  border: none;
  width: 100%;
  margin: 0.1rem 0;
  padding:  0.5rem 0rem;
  background-color: #415f74;
  opacity: 0.6;
  color: white;
  text-align: center;
  
}

.custom-input::placeholder {
  color: white;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .custom-input {
    width: 100%;
    
  }
  
}

.custom-input-container.signin-screen {
  background-color: #415f74;
  padding: 0.5rem 0.5rem;
}

.custom-input-container:not(.signin-screen) .icon {
  display: none;
}


.username-label {
  font-size:larger;
  font-weight:400;
}

