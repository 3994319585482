
  
  /* Responsivni stilovi za manje ekrane */
  @media (max-width: 768px) {
    .data-table th,
    .data-table td {
      padding: 5px;
      font-size: 12px;
    }
    
    .data-table {
      overflow-x: hidden; /* Dodajte ovu liniju kako biste sprečili horizontalni scroll */
    }
  
    .data-table .btn {
      font-size: 10px;
    }
  }
  /* PlayersScreen.css */

  
/* Sakrijte treću i četvrtu kolonu na manjim ekranima (maksimalna širina 768px) */
@media (max-width: 768px) {
  /* .data-table th:nth-child(3),
  .data-table td:nth-child(3),
  .data-table th:nth-child(4),
  .data-table td:nth-child(4) {
    display: none;
  } */

  /* Ako je poslednja kolona sa akcijama, ne sakrivajte je */
  .data-table th:last-child,
  .data-table td:last-child {
    display: table-cell;
  }
}

@media (max-width: 768px) {
  .data-table td:last-child button {
    display: block;
    margin-top: 10px; /* Dodajte margine kako biste razdvojili dugmad */
  }
}






td.icon-column {
  min-width: 0 !important; /* Omogućava da se kolona smanji na minimum, tako da nema nepotrebnog prostora sa desne strane */
  white-space: nowrap !important; /* Sprječava prelamanje teksta u koloni */
  width: 1% !important; /* Definiše minimalnu širinu kolone na 1%, ali će se automatski proširiti na sadržaj */
}

td.icon-column .btn {
  width: auto; /* Vratite širinu dugmeta na automatsko, kako biste odgovarali ikoni */
}


/* Dodajte nove stilove za boje parnih i neparnih redova tabele */
.even-row td,
.even-row th {
  background-color: rgba(226, 232, 235, 0.1) !important;
}

.odd-row td,
.odd-row th {
  background-color: rgba(236, 242, 244, 0.2) !important;
}


.odd-row:not(:hover) {
  border: 3px solid transparent; /* Postavite border na transparentan kada nije hoverovan */
}

.odd-row:hover {
  border: 3px solid rgba(255, 255, 255, 0.2); /* Postavite border na željenu vrednost kada je hoverovan */
}
.table{
  border-collapse: unset;
}
