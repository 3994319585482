*{
  --bs-gutter-x: 0!important;
  --bs-gutter-y: 0!important;
}

.notificationMessageContainer {
  display: flex;
  flex-direction: column;
  border: 2px solid white; /* Boja granice bela i debljina 2px */
  height: 150px; /* Postavljamo fiksnu visinu kontejnera */
  width: 300px; /* Postavljamo fiksnu širinu kontejnera */
  overflow: hidden; /* Sakrivanje prelaznog sadržaja */
  padding: 10px!important;
}

.square {
  width: 100%; /* Postavljanje širine na 100% */
  padding: 1rem; /* Dodavanje prostora unutar kvadrata */
  margin-bottom: 1rem; /* Dodavanje prostora ispod kartice */
  border-radius: 0.25rem; /* Zaobljeni rubovi */
  border: 2px solid white; /* Debljina granice i bela boja */
}

.withPath:hover {
  /* Stilovi za hover */
  background-color: #f0f0f0; /* Primer boje pozadine za hover */
  color: black;
}
.withPath{
  padding: 10px;
}

.notificationMessageContainer h6,
.notificationMessageContainer h2 {
  width: 100%;
  overflow-wrap: break-word; /* Omogućava prelamanje teksta */
}
@media screen and (max-width: 468px) {
  .notificationMessageContainer{
  margin-left: 5px;
  width: 97%!important;
  }
 
}